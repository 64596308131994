import { Form } from 'react-bootstrap'
import { InputText } from '../../../../../../components/Inputs'
import { useLang } from '../../../../../../language'
import { InputSelect } from '../../../../../../components/Inputs/InputSelect'
import { convertStaticServiceToArray } from '../../../../../../services/helpers'

export const FilterFormHead = ({ formValuesSearch, handleInputChangeSearch, showFilter, fetchParametrosGETData }: any) => {

    const Lang = useLang()

    return (
        <>
            <Form.Group className="col-12 col-sm-6 col-md-6 col-lg-4 col-xl-4 col-xxl-3">
                <InputText
                    label={Lang('CADENA')}
                    name='magic'
                    value={formValuesSearch.magic}
                    size='sm'
                    onChange={handleInputChangeSearch}
                />
            </Form.Group>

            {
                fetchParametrosGETData?.data?.['tkentidades']?.length > 1 &&
                <Form.Group className="col-12 col-sm-6 col-md-4 col-lg-4 col-xl-4 col-xxl-3">
                    <InputSelect
                        label={Lang('ENTIDAD') + ':'}
                        name='identidad'
                        value={formValuesSearch.identidad}
                        onChange={handleInputChangeSearch}
                        size='sm'
                        values={
                            convertStaticServiceToArray(fetchParametrosGETData?.data?.['tkentidades'] || {})
                        }
                    />
                </Form.Group>
            }

            <Form.Group className="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-3 col-xxl-3">
                <InputSelect
                    label={Lang('PRODUCTO') + ':'}
                    name='producto'
                    value={formValuesSearch.producto}
                    onChange={handleInputChangeSearch}
                    size='sm'
                    values={fetchParametrosGETData?.data?.['tkproductos']?.map((item: any) => {
                        return {
                            id: item.alias,
                            nombre: item.nombre_es
                        }
                    })}
                />
            </Form.Group>
            <Form.Group className="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-3 col-xxl-2">
                <InputSelect
                    label={Lang('ESTADO') + ':'}
                    name='estado'
                    value={formValuesSearch.estado}
                    onChange={handleInputChangeSearch}
                    size='sm'
                    values={
                        convertStaticServiceToArray(fetchParametrosGETData?.data?.['tkestados'] || {})
                    }
                />
            </Form.Group>

        </>
    )
}
