import { faArrowRight, faSearch } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { useEffect, useState } from 'react'
import { Form, Modal } from 'react-bootstrap'
import { ButtonSpinner } from '../../ButtonSpinner/ButtonSpinner'
import { InputText } from '../../Inputs'
import TableAdvanced from '../../Tables/TableAdvanced/TableAdvanced'
import { getValuesParams } from '../../../helpers/helpers'
import { useForm } from '../../../hooks/useForm'
import { useLang } from '../../../language'
import { EntidadesServices } from '../../../modules/administracion/pages/Entidades/services/entidades.service'
import { InputSelect } from '../../Inputs/InputSelect'
import { ParametrosServices } from '../../../services/parametros.service'

export const FilterEntidad = ({ showModal_FiltroEntidades, handleCloseModal_FiltroEntidades, setFilterEntidad, params = {}, filteridtipoentidad }: any) => {

    const Lang = useLang()

    const [fetchEntidadesGET, fetchEntidadesGETData] = EntidadesServices.GET()

    const [formValuesSearch2, handleInputChangeSearch2, handleFormChange2, resetformValuesSearch2] = useForm({
        page: 1,
        limit: '',
        magic: '',
        ...params
    });

    const handleSearch: any = (e: any) => {

        if (e) {
            e.preventDefault();
        }

        if (formValuesSearch2.page !== 1) {

            handleFormChange2({
                ...formValuesSearch2,
                page: 1
            });

        } else {

            let formValuesSearch2Temp = { ...formValuesSearch2 }

            Object.keys(formValuesSearch2Temp).forEach((k) => formValuesSearch2Temp[k] === '' && delete formValuesSearch2Temp[k]);

            fetchEntidadesGET({
                params: {
                    ...formValuesSearch2Temp
                }
            })
        }
    }

    useEffect(() => {
        if (showModal_FiltroEntidades.value) {
            // Obtener todos los valores existentes en el form de values Search
            const urlParams = getValuesParams(formValuesSearch2)

            fetchEntidadesGET({
                params: {
                    ...urlParams
                }
            })
        }

    }, [formValuesSearch2.page, formValuesSearch2.order_by, formValuesSearch2.limit])

    const allActions: any = []

    const allActionsPerLine = [
        {
            label: <FontAwesomeIcon icon={faArrowRight} className='' />,
            action: (item: any) => {
                setFilterEntidad({
                    id: item.id,
                    nombre: item.nombre,
                    sedes: item.sedes,
                    // sedes: [
                    //     {
                    //         nombresede: 'nombre1',
                    //         identidadsede: 'nombre1'
                    //     },
                    //     {
                    //         nombresede: 'nombre2',
                    //         identidadsede: 'nombre2'
                    //     }
                    // ]
                })
                handleCloseModal_FiltroEntidades()
            },
            withoutID: true,
        }
    ]

    const handleChangeCheckAll = (e: any) => {

        if (e.target.checked) {

            selectAllItems()

        } else {

            unselectAllItems()
        }
    }

    const headersTable = [
        <small>{Lang('NOMBRE')}</small>,
        <small>CIF/NIF</small>,
        ''
    ]

    const dataLineTable = [
        {
            className: 'max-w-15rem',
            name: 'nombre'
        },
        {
            className: '',
            name: 'cifnif'
        }
    ]

    useEffect(() => {

        if (showModal_FiltroEntidades.value) {

            const urlParams = getValuesParams(formValuesSearch2)

            fetchEntidadesGET({
                params: {
                    ...urlParams
                }
            })
        }

    }, [showModal_FiltroEntidades.value])

    const [excluidos, setExcluidos] = useState([])
    const [incluidos, setIncluidos] = useState([])

    const [checkAll, setCheckAll] = useState(false)

    const selectAllItems = () => {
        setCheckAll(true)
        setIncluidos([])
        setExcluidos([])
    }

    const unselectAllItems = () => {
        setCheckAll(false)
        setIncluidos([])
        setExcluidos([])
    }


    const [fetchTiposEntidadParam, fetchTiposEntidadParamData] = ParametrosServices.GET()

    useEffect(() => {

        if (filteridtipoentidad) {

            fetchTiposEntidadParam({
                params: {
                    tiposentidades: true,
                    status: 'activos'
                }
            })
        }

    }, [])


    return (
        <div>
            <Modal show={showModal_FiltroEntidades.value} onHide={handleCloseModal_FiltroEntidades} size='lg' id='ModalRegistro'>
                <Modal.Header className='border-bottom-0 py-4'>
                    <Modal.Title className='h6'>{Lang('SELECCIONE_UNA_ENTIDAD')}</Modal.Title>
                    <button type="button" className="btn-close mx-0" aria-label="Close" onClick={handleCloseModal_FiltroEntidades}></button>
                </Modal.Header>
                <Modal.Body className='px-4 py-0'>

                    <div className="row">
                        <Form.Group className="mb-3 ms-auto col">
                            <InputText
                                label={Lang('CADENA') + ':'}
                                name='magic'
                                size='sm'
                                value={formValuesSearch2.magic}
                                onChange={handleInputChangeSearch2}
                            />
                        </Form.Group>

                        {
                            filteridtipoentidad &&
                            <Form.Group className="mb-3 col">
                                <InputSelect
                                    label={Lang('TIPO_DE_ENTIDAD') + ':'}
                                    size='sm'
                                    name='idtipoentidad'
                                    value={formValuesSearch2.idtipoentidad}
                                    onChange={handleInputChangeSearch2}
                                    values={fetchTiposEntidadParamData.data || []}
                                />
                            </Form.Group>
                        }

                        <div className="col flex-grow-0 text-nowrap mb-3">
                            <div className="d-flex align-items-end justify-content-end h-100">

                                <ButtonSpinner
                                    variant="secondary" type="submit" size='sm' className=""
                                    handleSubmit={handleSearch} fetchDataLoading={[fetchEntidadesGETData.loading]}
                                    value={Lang('BUSCAR')} icon={<FontAwesomeIcon icon={faSearch} className='me-1' />}
                                />
                            </div>

                        </div>
                    </div>

                    <TableAdvanced>

                        <TableAdvanced.Head
                            title={Lang('LISTA_DE_ENTIDADES')}
                            allActions={allActions}
                        />

                        <TableAdvanced.Body
                            headers={headersTable}
                            dataLineTable={dataLineTable}
                            data={fetchEntidadesGETData.data?.items}
                            isLoading={fetchEntidadesGETData.loading}

                            excluidos={excluidos}
                            incluidos={incluidos}
                            setExcluidos={setExcluidos}
                            setIncluidos={setIncluidos}

                            checkAll={checkAll}
                            allActionsPerLine={allActionsPerLine}
                            checkMultiples={false}
                        />

                        <TableAdvanced.Footer
                            total={fetchEntidadesGETData.data?.total}
                            handleFormChange={handleFormChange2}
                            formValuesSearch={formValuesSearch2}
                            perPage={fetchEntidadesGETData.data?.per_page}
                        />

                    </TableAdvanced>
                </Modal.Body>

            </Modal>



        </div>
    )
}
