import { faArrowRight, faSearch } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { useEffect, useState } from 'react'
import { Form, Modal } from 'react-bootstrap'
import { ButtonSpinner } from '../../ButtonSpinner/ButtonSpinner'
import { InputText } from '../../Inputs'
import TableAdvanced from '../../Tables/TableAdvanced/TableAdvanced'
import { getValuesParams } from '../../../helpers/helpers'
import { useForm } from '../../../hooks/useForm'
import { useLang } from '../../../language'
import { PersonasServices } from '../../../modules/administracion/pages/Personas/services/personas.service'

export const FilterPersona = ({ showModal_FiltroPersonas, handleCloseModal_FiltroPersonas, setFilterPersona, params = {} }: any) => {

    const Lang = useLang()

    const [fetchPersonasGET, fetchPersonasGETData] = PersonasServices.GET()

    const [formValuesSearch2, handleInputChangeSearch2, handleFormChange2, resetformValuesSearch2] = useForm({
        page: 1,
        limit: '',
        nombreyapelldios: '',
        ...params
    });

    const handleSearch: any = (e: any) => {

        if (e) {
            e.preventDefault();
        }

        if (formValuesSearch2.page !== 1) {

            handleFormChange2({
                ...formValuesSearch2,
                page: 1
            });

        } else {

            let formValuesSearch2Temp = { ...formValuesSearch2 }

            Object.keys(formValuesSearch2Temp).forEach((k) => formValuesSearch2Temp[k] === '' && delete formValuesSearch2Temp[k]);

            fetchPersonasGET({
                params: {
                    ...formValuesSearch2Temp
                }
            })
        }
    }

    useEffect(() => {
        if (showModal_FiltroPersonas.value) {

            // Obtener todos los valores existentes en el form de values Search
            const urlParams = getValuesParams(formValuesSearch2)

            fetchPersonasGET({
                params: {
                    ...urlParams
                }
            })

        }
    }, [formValuesSearch2.page, formValuesSearch2.order_by, formValuesSearch2.limit])

    const allActions: any = []

    const allActionsPerLine = [
        {
            label: <FontAwesomeIcon icon={faArrowRight} className='' />,
            action: (item: any) => {
                setFilterPersona({
                    id: item.id,
                    nombre: item.persona
                })
                handleCloseModal_FiltroPersonas()
            },
            withoutID: true,
        }
    ]

    const headersTable = [
        <small>{Lang('PERSONA')}</small>,
        <small>{Lang('NUM_COLEGIADO')}</small>,
        <small>DNI</small>,
        ''
    ]

    const dataLineTable = [
        {
            className: 'max-w-15rem',
            name: 'persona'
        },
        {
            className: '',
            name: 'numeroasociado'
        },
        {
            className: '',
            name: 'dni'
        }
    ]

    useEffect(() => {

        if (showModal_FiltroPersonas.value) {

            if (formValuesSearch2.magic) {

                const handler = setTimeout(() => {

                    handleSearch()

                }, 700);

                return () => clearTimeout(handler);

            } else {
                
                handleSearch()
            }
        }

    }, [showModal_FiltroPersonas.value, formValuesSearch2.magic]);

    const [excluidos, setExcluidos] = useState([])
    const [incluidos, setIncluidos] = useState([])

    const [checkAll, setCheckAll] = useState(false)

    return (
        <div>
            <Modal show={showModal_FiltroPersonas.value} onHide={handleCloseModal_FiltroPersonas} size='lg' id='ModalRegistro'>
                <Modal.Header className='border-bottom-0 py-4'>
                    <Modal.Title className='h6'>{Lang('SELECCIONE_UNA_PERSONA')}</Modal.Title>
                    <button type="button" className="btn-close mx-0" aria-label="Close" onClick={handleCloseModal_FiltroPersonas}></button>
                </Modal.Header>
                <Modal.Body className='px-4 py-0'>

                    <div className="row">
                        <Form.Group className="mb-3 ms-auto col">
                            <InputText
                                label={Lang('CADENA') + ':'}
                                name='magic'
                                size='sm'
                                value={formValuesSearch2.magic}
                                onChange={handleInputChangeSearch2}
                            />
                        </Form.Group>

                        <div className="col flex-grow-0 text-nowrap mb-3">
                            <div className="d-flex align-items-end justify-content-end h-100">

                                <ButtonSpinner
                                    variant="secondary" type="submit" size='sm' className=""
                                    handleSubmit={handleSearch} fetchDataLoading={[fetchPersonasGETData.loading]}
                                    value={Lang('BUSCAR')} icon={<FontAwesomeIcon icon={faSearch} className='me-1' />}
                                />
                            </div>
                        </div>
                    </div>

                    <TableAdvanced>

                        <TableAdvanced.Head
                            title={Lang('LISTA_DE_PERSONAS')}
                            allActions={allActions}
                        />

                        <TableAdvanced.Body
                            headers={headersTable}
                            dataLineTable={dataLineTable}
                            data={fetchPersonasGETData.data?.items}
                            isLoading={fetchPersonasGETData.loading}

                            excluidos={excluidos}
                            incluidos={incluidos}
                            setExcluidos={setExcluidos}
                            setIncluidos={setIncluidos}

                            checkAll={checkAll}
                            allActionsPerLine={allActionsPerLine}
                            checkMultiples={false}
                        />

                        <TableAdvanced.Footer
                            total={fetchPersonasGETData.data?.total}
                            handleFormChange={handleFormChange2}
                            formValuesSearch={formValuesSearch2}
                            perPage={fetchPersonasGETData.data?.per_page}
                        />

                    </TableAdvanced>
                </Modal.Body>

            </Modal>



        </div>
    )
}
