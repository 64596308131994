import { useEffect, useRef, useState } from 'react'
import { Button, Form, InputGroup } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import { BreadcrumbLine } from '../../../../../../../components/BreadcrumbLine/BreadcrumbLine';
import { useForm } from '../../../../../../../hooks/useForm';
import { useLang } from '../../../../../../../language';
import { hasTextContent, isStatusSucess } from '../../../../../../../helpers/helpers';
import { TicketClientServices } from '../../controllers/tickets.service';
import { TicketClientAdapters } from '../../controllers/tickets.adapter';
import { InputText } from '../../../../../../../components/Inputs';
import { InputSelect } from '../../../../../../../components/Inputs/InputSelect';
import { FilterPersona } from '../../../../../../../components/Modals/ModalFilters/FilterPersona';
import { FilterEntidad } from '../../../../../../../components/Modals/ModalFilters/FilterEntidad';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSave, faUser, faUsers, faWarehouse } from '@fortawesome/free-solid-svg-icons';
import { ButtonSpinner } from '../../../../../../../components/ButtonSpinner/ButtonSpinner';

import 'tinymce/tinymce';
import 'tinymce/icons/default';
import 'tinymce/themes/silver';
import 'tinymce/plugins/paste';
import 'tinymce/plugins/link';
import 'tinymce/plugins/image';
import 'tinymce/plugins/table';
import 'tinymce/plugins/code';
import 'tinymce/plugins/fullpage';
import 'tinymce/plugins/lists';

import 'tinymce/skins/ui/oxide/skin.min.css';
import { Editor } from '@tinymce/tinymce-react';
import { InputFile } from '../../../../../../../components/Inputs/InputFile';
import { ModalStatusServer } from '../../../../../../../components/Modals/ModalStatusServer';
import { InputChips } from '../../../../../../../components/Inputs/inputChips';
import { convertStaticServiceToArray } from '../../../../../../../services/helpers';
import { cloneDeep } from 'lodash';
import { MsgInput } from '../../../../../../../components/MsgInput/MsgInput';
import { useValidator } from '../../../../../../../hooks/useValidator';
import { FilterPersonaTicket } from '../../../../../../../components/Modals/ModalFilters/FilterPersonaTicket';

const TicketNuevo = ({ pathName, fetchParametrosGETData }: any) => {

  const navigate = useNavigate()

  const editorRef: any = useRef(null);

  const Lang = useLang()

  const inputFileFichero: any = useRef(null)

  const [formTicket, handleInputTicket, handleFormTicket, resetFormTicket] = useForm({
    ...TicketClientAdapters.GET.receive.schema({}),
    listadestinatarios: []
  })

  const [valuesValidationTicket, handleValidationTicket, handleResetValidationTicket] = useValidator({
    descripcion: { isOk: true, msgError: [Lang('ALERT_OBLIGATORIO_GENERICO')] },
    ticket: { isOk: true, msgError: [Lang('ALERT_OBLIGATORIO_GENERICO')] },
    producto: { isOk: true, msgError: [Lang('ALERT_OBLIGATORIO_GENERICO')] },
    urgencia: { isOk: true, msgError: [Lang('ALERT_OBLIGATORIO_GENERICO')] },
  })

  const BreadcrumbLineItems = [
    {
      title: Lang('TICKETS'),
      link: '/ticketing/' + pathName
    },
    {
      title: Lang('NUEVO_TICKET'),
      link: '/ticket'
    }
  ]

  const handleEditorChange = (content: any) => {

    // setContentEditor(content);

    const target = {
      name: 'descripcion',
      value: content,
    }
    handleInputTicket({ target })
  }


  const handleDestinatariosChange = (data: any) => {

    // setContentEditor(content);

    const target = {
      name: 'listadestinatarios',
      value: data,
    }
    handleInputTicket({ target })
  }

  // --------------- Filtro Persona

  const [showModal_FiltroPersonas, setShowModal_FiltroPersonas] = useState({
    value: false,
    payload: {}
  });

  const handleCloseModal_FiltroPersonas = () => setShowModal_FiltroPersonas({
    value: false,
    payload: {}
  });

  const handleShowModal_FiltroPersonas = (payload: any) => setShowModal_FiltroPersonas({
    value: true,
    payload
  });

  const [filterPersona, setFilterPersona] = useState({
    id: '',
    nombre: ''
  })

  useEffect(() => {

    const obj = {
      target: {
        name: 'idpersonaregistro',
        value: filterPersona.id
      }
    }

    handleInputTicket(obj)

  }, [filterPersona])

  useEffect(() => {
    if (!formTicket.idpersonaregistro) {
      setFilterPersona({
        id: '',
        nombre: ''
      })
    }
  }, [formTicket.idpersonaregistro])


  // ------------- Filtro Entidades

  const [showModal_FiltroEntidades, setShowModal_FiltroEntidades] = useState({
    value: false,
    payload: {}
  });

  const handleCloseModal_FiltroEntidades = () => setShowModal_FiltroEntidades({
    value: false,
    payload: {}
  });

  const handleShowModal_FiltroEntidades = (payload: any) => setShowModal_FiltroEntidades({
    value: true,
    payload
  });

  const [filterEntidad, setFilterEntidad] = useState({
    id: '',
    nombre: ''
  })

  useEffect(() => {

    const obj = {
      target: {
        name: 'identidad',
        value: filterEntidad.id
      }
    }

    handleInputTicket(obj)

  }, [filterEntidad])

  useEffect(() => {
    if (!formTicket.identidad) {
      setFilterEntidad({
        id: '',
        nombre: ''
      })
    }
  }, [formTicket.identidad])


  // Filtro destinatarios

  const [showModal_FiltroPersonasDest, setShowModal_FiltroPersonasDest] = useState({
    value: false,
    payload: {}
  });

  const handleCloseModal_FiltroPersonasDest = () => setShowModal_FiltroPersonasDest({
    value: false,
    payload: {}
  });

  const handleShowModal_FiltroPersonasDest = (payload: any) => setShowModal_FiltroPersonasDest({
    value: true,
    payload
  });

  const [filterPersonaDest, setFilterPersonaDest] = useState({
    id: '',
    nombre: ''
  })

  useEffect(() => {

    if (filterPersonaDest.id) {

      let arrayTemp = formTicket['listadestinatarios']

      arrayTemp.push({
        id: filterPersonaDest.id,
        name: filterPersonaDest.nombre
      })

      const obj = {
        target: {
          name: 'listadestinatarios',
          value: arrayTemp
        }
      }

      handleInputTicket(obj)
    }

  }, [filterPersonaDest])


  const [fetchTicketPOST, fetchTicketPOSTData] = TicketClientServices.POST()

  const handleSubmitPOST = (e: any) => {

    const formTicketOnlyValidation = cloneDeep(formTicket)
    formTicketOnlyValidation.descripcion = hasTextContent(formTicketOnlyValidation.descripcion)

    if (handleValidationTicket(formTicketOnlyValidation)) {

      const documents: any = Array.from(inputFileFichero?.current?.files)

      const formTicketTemp: any = cloneDeep(formTicket)


      const destinatarios = formTicketTemp?.['listadestinatarios']?.map((item: any) => item.id) || []

      delete formTicketTemp.listadestinatarios

      let formData = new FormData();

      for (const key in formTicketTemp) {
        formData.append(key, formTicketTemp[key])
      }

      for (const file of documents) {
        formData.append('documents[]', file);
      }

      for (const item of destinatarios) {
        formData.append('listadestinatarios[]', item);
      }

      fetchTicketPOST({
        body: formData
      })

    }


  }

  useEffect(() => {

    if (isStatusSucess(fetchTicketPOSTData.status)) {

      navigate('/ticketing/tickets/' + fetchTicketPOSTData?.data?.data?.idticket)
    }

  }, [fetchTicketPOSTData]);

  useEffect(() => {

    if (fetchParametrosGETData?.data?.['tkentidades']) {

      let arrayEntidades = convertStaticServiceToArray(fetchParametrosGETData?.data?.['tkentidades'] || {}) || []

      handleFormTicket({
        ...formTicket,
        identidad: arrayEntidades[0]?.id
      })
    }

  }, [fetchParametrosGETData]);



  return (

    <div className="container-fluid">
      <div className="row">
        <div className="col-12">
          <div className='d-flex justify-content-between align-items-center mb-1 flex-wrap'>
            <BreadcrumbLine BreadcrumbLineItems={BreadcrumbLineItems} />
            <div className='d-flex flex-wrap'>

            </div>
          </div>
        </div>

        <div className="col-12">

          <h5 className='mb-3 text-center'>{Lang('NUEVO_TICKET')}</h5>

        </div>

        <div className="col-lg-12 animate__animated animate__fadeInUp mx-auto">

          <div className={`p-3 card mb-4`}>

            <div className="row">

              <div className="col-12">

                <Form className='row'>

                  {
                    fetchParametrosGETData?.data?.['tkentidades']?.length > 1 &&
                    <Form.Group className="col-12 col-sm-6 col-md-4 col-lg-4 col-xl-4 col-xxl-3">
                      <InputSelect
                        label={Lang('ENTIDAD') + ':'}
                        name='identidad'
                        value={formTicket.identidad}
                        onChange={handleInputTicket}
                        size='sm'
                        values={
                          convertStaticServiceToArray(fetchParametrosGETData?.data?.['tkentidades'] || {})
                        }
                      />
                    </Form.Group>
                  }

                  <Form.Group className="mb-3 col-12 col-sm-6 col-md-4 col-lg-3 col-xl-3 col-xxl-3">
                    <InputSelect
                      label={Lang('PRODUCTO') + ':'}
                      name='producto'
                      value={formTicket.producto}
                      onChange={handleInputTicket}
                      size='sm'
                      values={fetchParametrosGETData?.data?.['tkproductos']?.map((item: any) => {
                        return {
                          id: item.alias,
                          nombre: item.nombre_es
                        }
                      })}
                      validation={valuesValidationTicket}
                    />
                  </Form.Group>

                  <Form.Group className="mb-3 col-12 col-sm-6 col-md-4 col-lg-3 col-xl-3 col-xxl-3">
                    <InputSelect
                      label={Lang('URGENCIA') + ':'}
                      name='urgencia'
                      value={formTicket.urgencia}
                      onChange={handleInputTicket}
                      size='sm'
                      values={
                        convertStaticServiceToArray(fetchParametrosGETData?.data?.['tkurgencias'] || {})
                      }
                      validation={valuesValidationTicket}
                    />
                  </Form.Group>

                  <div className="col-12">

                    <div className="row">
                      <div className="col-lg-6">

                        <div className="row">

                          <Form.Group className="mb-3 col-12">
                            <InputText
                              label={Lang('TICKET') + ':'}
                              name='ticket'
                              value={formTicket.ticket}
                              onChange={handleInputTicket}
                              size='sm'
                              validation={valuesValidationTicket}
                            />
                          </Form.Group>

                          <Form.Group className="mb-3 col-12">
                            <div className="d-flex justify-content-between align-items-end mb-2">
                              <label className="fw-500 form-label me-2 mb-0">{Lang('DESTINATARIOS')} (CC):</label>
                              <Button variant="primary" onClick={handleShowModal_FiltroPersonasDest} size='sm' className="ms-auto">
                                <FontAwesomeIcon icon={faUsers} />
                              </Button>
                            </div>

                            <InputChips
                              data={formTicket.listadestinatarios}
                              setData={handleDestinatariosChange}
                            />
                          </Form.Group>

                          <Form.Group className="mb-3 col-12">
                            <Form.Label className='fw-500'>{Lang('ADJUNTO')}: *</Form.Label>
                            <InputFile
                              name='file'
                              // onChange={handleInputEnvioEmail}
                              referencia={inputFileFichero}
                              multiple
                            />
                          </Form.Group>

                        </div>
                      </div>


                      <div className="col-lg-6">
                        <div className="row">
                          <Form.Group className="mb-3 col-12">

                            <Form.Label className='fw-500 d-block mb-2'>
                              <div className='me-auto'>{Lang('MENSAJE')}</div>
                            </Form.Label>

                            <Editor
                              onInit={(evt, editor) => editorRef.current = editor}
                              // initialValue={dataInicial}
                              init={{
                                skin: false,
                                branding: false,
                                content_css: false,
                                height: 300,
                                menubar: false,
                                relative_urls: false,
                                remove_script_host: false,
                                convert_urls: false,
                                image_title: true,
                                file_picker_callback: function (cb, value, meta) {
                                  var input = document.createElement('input');
                                  input.setAttribute('type', 'file');
                                  input.setAttribute('accept', 'image/*');

                                  input.onchange = function () {

                                    var thisTemp: any = this

                                    var file = thisTemp.files[0];

                                    var reader: any = new FileReader();
                                    reader.onload = function () {
                                      var id = 'blobid' + (new Date()).getTime();

                                      var blobCache = editorRef.current.editorUpload.blobCache;
                                      var base64 = reader.result.split(',')[1];
                                      var blobInfo = blobCache.create(id, file, base64);
                                      blobCache.add(blobInfo);

                                      cb(blobInfo.blobUri(), { title: file.name });
                                    };
                                    reader.readAsDataURL(file);
                                  };

                                  input.click();
                                },
                                plugins: [
                                  'link image',
                                  'table paste',
                                  'code',
                                  'fullpage',
                                  'lists',
                                ],
                                toolbar:
                                  'undo redo | formatselect | bold italic forecolor backcolor | \
                                            alignleft aligncenter alignright alignjustify | \
                                            bullist numlist outdent indent | removeformat | help code | image',
                                paste_word_valid_elements: 'a,table,tbody,tr,td,span,div,style',
                                extended_valid_elements: 'style',
                                custom_elements: 'style'
                              }}
                              value={formTicket.descripcion}
                              onEditorChange={handleEditorChange}
                            />
                            <MsgInput obj={valuesValidationTicket} name='descripcion' />

                          </Form.Group>
                        </div>
                      </div>

                    </div>
                  </div>

                  <div className="col-12">
                    <div className="d-flex justify-content-end">

                      <ButtonSpinner
                        variant="secondary" type="button" className=""
                        handleSubmit={handleSubmitPOST} fetchDataLoading={[fetchTicketPOSTData.loading]}
                        value={Lang('CREAR_TICKET')} icon={<FontAwesomeIcon icon={faSave} className='me-1' />}
                      />
                    </div>
                  </div>

                </Form>

              </div>

            </div>

          </div>
        </div>
      </div>

      <FilterPersona
        showModal_FiltroPersonas={showModal_FiltroPersonas}
        handleCloseModal_FiltroPersonas={handleCloseModal_FiltroPersonas}
        setFilterPersona={setFilterPersona}
      />

      <FilterEntidad
        showModal_FiltroEntidades={showModal_FiltroEntidades}
        handleCloseModal_FiltroEntidades={handleCloseModal_FiltroEntidades}
        setFilterEntidad={setFilterEntidad}
      />

      <FilterPersonaTicket
        showModal_FiltroPersonas={showModal_FiltroPersonasDest}
        handleCloseModal_FiltroPersonas={handleCloseModal_FiltroPersonasDest}
        setFilterPersona={setFilterPersonaDest}
        idEntidad={formTicket.identidad}
      />

      <ModalStatusServer fetchData={fetchTicketPOSTData} />

    </div>
  )
}

export default TicketNuevo;